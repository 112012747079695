import * as Turbo from "@hotwired/turbo"
import 'arrive'
import 'bootstrap'
import 'chartkick/chart.js'

// stimulus support
import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'
const application = Application.start()
const controllers = import.meta.glob('~/**/*_controller.js', { eager: true })

registerControllers(application, controllers)
import CheckboxSelectAll from 'stimulus-checkbox-select-all'
application.register('checkbox-select-all', CheckboxSelectAll)

// mine
// import '~/javascript/ace_loader'
import '~/javascript/my_scripts'
